<script lang="ts">
  import { onMount } from 'svelte';

  export let wWidth;

  let settingStyle: 'swipe' | 'scroll' = 'swipe';

  let sections = [];
  let features = [];

  let isFormVisible = false;

  function handleWindowKeyDown(event) {
    if (event.key === 'Escape') {
      isFormVisible = false;
    }
  }

  function updateSections() {
    wWidth = window.innerWidth;
    settingStyle = wWidth > 800 ? 'scroll' : 'swipe';
    sections = [
      {
        id: 'what-is-juicy-timer',
        title: 'What is a juicy timer?',
        texts: [
          `Distraction free Pomodoro timer inspired by <a target="blank" href="https://francescocirillo.com/pages/pomodoro-technique">The Pomodoro Technique</a> invented by Francesco Cirillo. Distraction free means you are not going to be distracted by annoying pop-ups, prompts to sign up to a newsletter or anything else like that. Never!`,
        ],
      },

      {
        id: 'how-does-juicy-timer-work',
        title: 'How does the juicy timer work?',
        texts: [
          `You just ${settingStyle} the timer to set your preferred time and hit start. That's it - your countdown is set and ticking for any kind of focused work you decided to do - study for your school, learn a new skill, build a website or maybe cook some delicious pasta! Once the time is up, you will be notified by a success sound and the finish screen goes up. Congrats, you are one step closer to achieving your goals.`,
        ],
      },

      {
        id: 'what-do-you-mean-by-pomodoro-technique',
        title: 'What do you mean by Pomodoro technique?',
        texts: [
          `We mean the time management technique called <a target="blank" href="https://francescocirillo.com/pages/pomodoro-technique">The Pomodoro Technique</a> and invented by Francisco Cirillo in the 1980s - yes 1980s (${
            new Date().getFullYear() - 1980
          } years ago) and still relevant today. It uses a timer to break work into intervals. These are typically set to 25 minutes followed by a short, usually a 5 minute long break.`,
        ],
      },

      {
        id: 'why-is-it-called-Pomodoro?',
        title: 'Why is it called a Pomodoro?',
        texts: [
          'Each working interval is known as Pomodoro, Italian word for tomato. It originates from the tomato-shaped kitchen timer <a target="blank" href="https://en.wikipedia.org/wiki/Pomodoro_Technique">Francesco Cirillo used as a university student.</a>',
        ],
      },

      {
        id: 'why-is-pomodoro-25-minutes-long?',
        title: 'Why is Pomodoro 25 minutes long?',
        texts: [
          "25 minutes is proven to be the ideal length to be long enough to allow you finish a task, but also fairly short for people who struggle with focus. The interval does not have to be always 25 minutes though, more experienced people often increase their intervals up to 50 minutes, followed by a 10 minutes break. Also we have seen many people coming to Pomodoro and setting their timers at 15 minutes to begin with. It is always about finding out what works for you so give yourself plenty of opportunities to experiment. We've got your back!",
        ],
      },

      {
        id: 'is-pomodoro-technique-good-for-adhd',
        title: 'Is Pomodoro Technique good for ADHD?',
        texts: [
          'People with ADHD may find the Pomodoro Technique helpful for its structure. Breaking bigger tasks into shorter focus intervals and achieving small wins by finishing one after another can lead to a big victory in the end, that would not be possible without the structure. Setting a time limit for each interval can also help prevent hyperfocus on a certain task for an extensive period of time.',
        ],
      },

      {
        id: 'is-pomodoro-good-for-coding',
        title: 'Is Pomodoro good for coding?',
        texts: [
          "Pomodoro is ideal for tasks that require you to focus on your own, without the need for collaboration with others. Therefore it's great for activities such as coding. We recommend preparing as many requirements as possible beforehand, so that during the coding session you do not need to reach out to others for clarification. If any issue occurs unexpectedly, we recommend you to focus on what's known and continued with it. Use the break to reach out to your peers. The main reason for this advice is that to get a reply from your peers can take some time and this would stay on your mind, distracting you from the actual task.",
        ],
      },

      {
        id: 'is-pomodoro-good-for-studying',
        title: 'Is Pomodoro good for studying?',
        texts: [
          'Pomodoro is ideal for studying. Especially good for subjects and topics that are not very interesting and you simply need to get through them. During such tasks you might be even more tempted to check your mobile phone, social media notifications or become otherwise distracted. If you stick to your Pomodoro interval and leave your phone away, it makes even the most daunting subjects gradually learnable.',
        ],
      },

      {
        id: 'does-pomodoro-increase-productivity',
        title: 'Does Pomodoro increase productivity?',
        texts: [
          'The secret of Pomodoro is the power to overcome distractions, resulting in boost to your productivity right away. It is very important to set the focus interval at a length that works for you. If you feel that 25 minutes is too long and towards the end of the session you were barely focusing, try setting a shorter interval for your next session. Such immediate adjustments will help you find your perfect rhythm and let you increase the length of your Pomodoro sessions as you progress. Once you find the perfect rhythm, you will notice productivity boost almost immediately. Do not give up and keep on "juicing".',
        ],
      },

      {
        id: 'how-do-you-stick-to-pomodoro',
        title: 'How do you stick to Pomodoro?',
        texts: [
          "Sticking to Pomodoro is a habit that you need to build up. At first Pomodoro requires active conscious effort, but the more you keep practising Pomodoro the more natural it will become. Eventually, it will become second nature that will happen without losing any will power. We recommend bookmarking juicy timer in your browser, so that the next time you are about to use it it's just one click away.",
        ],
      },

      {
        id: 'is-pomodoro-good-for-cooking',
        title: 'Is Pomodoro good for cooking?',
        texts: [
          "That's a good question and even though the standard of paying attention for 25 minutes and taking a break for 5 minutes could cause some oven made recipes to burn, juicy timer can definitely help out with setting a time interval and let you know once time's up for any activity, cooking including!",
        ],
      },
      {
        id: 'pomodoro-pairing',
        title: 'What is Pomodoro pairing?',
        texts: [
          `It's a widely used pair programming technique used by many big tech companies (f.e. <a target="blank" href="https://shopify.engineering/pair-programming-explained#techniques">Shopify</a>). It helps you to work on the same task with your team and it's a great way to learn new skills and to get to know your team better. It's pretty simple, decide who will start pairing as a "driver", set a Pomodoro to 25 minutes and go. Take 5 minutes break, step away from pairing and do something individually. Then come back, start another Pomodoro for 25 minutes but this time the "driver" is the other team member while the previous "driver" is now the "navigator". It's OK to switch roles sometimes, but it's important to stick to the assigned role for the majority of one Pomodoro cycle.`,
        ],
      },
    ];
    features = [
      { text: 'Designed with zero distractions focus' },
      { text: 'Beautiful photo realsitic design' },
      {
        text: `Simple ${settingStyle} and click to set and start the Pomodoro interval`,
      },
      {
        text: 'Fully responsive support for mobile, tablet and desktop devices',
      },
      { text: 'Audio notification at the end of each Pomodoro interval' },
      { text: 'Choice of 20 audio notifications' },
    ];
  }

  onMount(() => {
    updateSections();
    window.addEventListener('keydown', handleWindowKeyDown);
    window.addEventListener('resize', () => updateSections());
  });
</script>

<section
  id="content"
  class="text-left bg-gradient-to-r text-tomato pl-6 pr-6 pt-6 pb-12 bg-scalewhite md:px-32 xl:px-80"
>
  <ul>
    <h2 class="text-3xl py-2">FAQs</h2>
    {#each sections as section}
      <li>
        <article id={section.id} class="w-full p-3 md:p-6">
          <h3 class="text-2xl text-tomato my-1">{section.title}</h3>
          {#each section.texts as text}
            <p class="text-lg text-bgblack font-mono">{@html text}</p>
          {/each}
        </article>
      </li>
    {/each}
  </ul>
  <ul>
    <h2 class="text-3xl py-2">Features</h2>
    {#each features as feature}
      <li class="px-3 py-1 text-lg md:px-6">
        <p>{feature.text}</p>
      </li>
    {/each}
  </ul>

  <div id="mobile-store" class="text-center pt-6">
    <p class="text-xl">Coming soon on...</p>
    <button class="border-none" on:click={() => (isFormVisible = true)}>
      <img
        alt="apple store and google play store badge"
        class="inline-block mt-2 mb-2 p-1"
        width={160}
        src="/both-stores.svg"
      />
    </button>
  </div>
  {#if isFormVisible}
    <div class="w-full h-full z-20 fixed text-center bg-black inset-0">
      <button
        class="absolute top-1 right-1 border-0 mt-1 mr-1"
        on:click={() => (isFormVisible = false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 hover:text-wesYellow text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-line-cap="round"
            stroke-line-join="round"
            stroke-width={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <iframe
        class="h-full w-full"
        title="Notify me sign up"
        src="https://cdn.forms-content.sg-form.com/93d9d2c0-8152-11ec-ae94-3a4b340be755"
      />
    </div>
  {/if}
</section>
