<script lang="ts">
  import LogRocket from 'logrocket';

  import Timer from './components/Timer.svelte';
  import Content from './components/Content.svelte';
  import Settings from './components/Settings.svelte';
  import Footer from './components/Footer.svelte';

  LogRocket.init('36-studio/juicy-timer');
  let wWidth: number | null = null;
  let soundEffect: HTMLAudioElement = new Audio();
  soundEffect.src = 'sounds/mixkit-happy-bells-notification-937.wav';
</script>

<svelte:window bind:innerWidth={wWidth} />

<main>
  <Timer {wWidth} {soundEffect} />
  <Content {wWidth} />
  <Settings {soundEffect} />
  <Footer footerMiddleSection={true} />
</main>
