<script>
  let savedSound = localStorage.getItem('sound');
  export let soundEffect;
  if (savedSound) soundEffect.src = savedSound;
  const sounds = [
    {
      src: 'sounds/mixkit-happy-bells-notification-937.wav',
      name: 'Happy Bells',
    },
    {
      src: 'sounds/mixkit-positive-notification-951.wav',
      name: 'Positive',
    },
    {
      src: 'sounds/mixkit-correct-answer-tone-2870.wav',
      name: 'Success',
    },
    {
      src: 'sounds/mixkit-retro-game-notification-212.wav',
      name: 'Retro Game',
    },
    {
      src: 'sounds/mixkit-doorbell-tone-2864.wav',
      name: 'Doorbell',
    },
    {
      src: 'sounds/mixkit-arcade-magic-notification-2342.wav',
      name: 'Arcade Magic',
    },
    {
      src: 'sounds/mixkit-classic-melodic-clock-strike-1058.wav',
      name: 'Classic Melodic Clock',
    },
    {
      src: 'sounds/mixkit-clear-announce-tones-2861.wav',
      name: 'Announce',
    },
    {
      src: 'sounds/mixkit-correct-answer-reward-952.wav',
      name: 'Reward',
    },
    {
      src: 'sounds/mixkit-guitar-notification-alert-2320.wav',
      name: 'Guitar Riff',
    },
    {
      src: 'sounds/mixkit-intro-transition-1146.wav',
      name: 'Magic Transition',
    },
    {
      src: 'sounds/mixkit-magic-marimba-2820.wav',
      name: 'Magic Marimba',
    },
    {
      src: 'sounds/mixkit-message-pop-alert-2354.mp3',
      name: 'Message Pop',
    },
    {
      src: 'sounds/mixkit-software-interface-remove-2576.wav',
      name: 'Oldschool Software Up',
    },
    {
      src: 'sounds/mixkit-software-interface-back-2575.wav',
      name: 'Oldschool Software Down',
    },
    {
      src: 'sounds/mixkit-software-interface-start-2574.wav',
      name: 'Oldschool Software Neutral',
    },
    {
      src: 'sounds/mixkit-tile-game-reveal-960.wav',
      name: 'Tile Game Reveal',
    },
    {
      src: 'sounds/mixkit-uplifting-flute-notification-2317.wav',
      name: 'Uplifting Flute',
    },
    {
      src: 'sounds/wilhelm.wav',
      name: 'Wilhelm (for all movie lovers 🍿)',
    },
    {
      src: 'sounds/mixkit-rooster-crowing-in-the-morning-2462.wav',
      name: 'Rooster (for all early birds 🦉)',
    },
  ];
</script>

<section
  id="settings"
  class="text-left bg-gradient-to-r text-tomato pl-6 pr-6 pt-6 pb-12 bg-scalewhite md:px-32 xl:px-80"
>
  <h2 class="text-3xl py-2">Settings</h2>
  <div class=" py-2 px-3 md:px-6">
    <h3 class="text-2xl text-tomato my-1">Notification sound</h3>
    {#each sounds as sound}
      <label class="py-3 md:py-1">
        <input
          type="radio"
          name="sound"
          value={sound.src}
          checked={soundEffect.src.includes(sound.src)}
          on:click={(ev) => {
            localStorage.setItem('sound', ev.target.value);
            soundEffect.src = ev.target.value;
            soundEffect.play();
          }}
        />
        {sound.name}</label
      >
    {/each}
  </div>
</section>
