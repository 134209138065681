<script lang="ts">
  import CountDown from './CountDown.svelte';
  import Modal from './Modal.svelte';
  import { DateTime } from 'luxon';
  import { onMount } from 'svelte';

  export let wWidth;
  let isOpen = false;
  const openClose = (toBe: boolean) => (isOpen = toBe);
  const blackImg = new Image();
  blackImg.src = '/resize/black.png';

  let countDown = 0;
  let sec = 0;
  let min = 0;
  let ignoreScroll = false;
  let frameIndex = 1;
  let lastTimerYScrollValue: number | null = null;

  export let soundEffect;
  const images = {};
  let scrolled = false;
  let startedAt = 0;
  let durationSeconds = 0;
  let loading = true;
  let outerDiv, img, context;

  const handleClick = () => {
    ignoreScroll = true;
    updateImage(0);
    startedAt = Math.floor(Date.now() / 1000);
    durationSeconds = countDown * 60;
    requestAnimationFrame(update);
    setTimeout(() => soundEffect.play(), durationSeconds * 1000 - 1000);
  };

  const update = () => {
    let now = Math.floor(Date.now() / 1000);
    let elapsedSeconds = now - startedAt;
    let secondsLeft = durationSeconds - elapsedSeconds;
    if (secondsLeft > 0) {
      sec = secondsLeft % 60;
      min = Math.floor(secondsLeft / 60);
      updateImage(
        frameCount - frameIndex + Math.floor(elapsedSeconds / 10.812),
      );
      requestAnimationFrame(update);
    } else {
      saveIntoLocalStorage(durationSeconds);
      min = 0;
      sec = 0;
      ignoreScroll = false;
      countDown = 0;
      startedAt = 0;
      durationSeconds = 0;
      outerDiv.scrollTo(0, 0);
      frameIndex = 1;
      updateImage(332);
      openClose(true);
    }
  };

  const frameCount = 332;

  const limitIndex = (index) => (index < 0 ? 0 : index > 332 ? 332 : index);

  const frameSrc = (index) => {
    return `resize/${limitIndex(index)}-designify.png`;
  };

  const updateImage = (index) => {
    context.drawImage(blackImg, -10, 0);
    context.drawImage(images?.[limitIndex(index)] || frameSrc(index), -10, 0);
  };

  const preloadImages = () => {
    for (let i = frameCount; i >= 0; i--) {
      const img = new Image();
      img.onload = () => {
        images[i] = img;
        if (Object.keys(images).length === 333) loading = false;
      };
      img.src = frameSrc(i);
    }
  };

  const saveIntoLocalStorage = (secondsDone: number): void => {
    const now = DateTime.now().setZone('Europe/London').toFormat('yyyy-MM-dd');

    interface Pomo {
      date: string;
      secondsSpent: number;
      pomosDone: number;
    }
    let pomos: string | undefined = localStorage.getItem('pomos');
    let pomosArray: Pomo[] = pomos ? JSON.parse(pomos) : [];

    let today: Pomo | null = pomosArray.find((pomo) => pomo.date === now);

    if (today) {
      today.secondsSpent += secondsDone;
      today.pomosDone += 1;
    } else {
      today = {
        date: now,
        secondsSpent: secondsDone,
        pomosDone: 1,
      };
      pomosArray.push(today);
    }

    localStorage.setItem('pomos', JSON.stringify(pomosArray));
  };

  onMount(() => {
    preloadImages();
    const canvas: HTMLCanvasElement = document.getElementById(
      'timer-canvas',
    ) as HTMLCanvasElement;
    context = canvas.getContext('2d');

    img = new Image();
    img.src = frameSrc(332);
    canvas.width = 320;
    canvas.height = 320;
    img.onload = function () {
      context.drawImage(img, -10, 0);
    };

    window.addEventListener('resize', () => {});

    outerDiv = document.getElementById('outer');

    outerDiv.addEventListener('scroll', () => {
      if (!ignoreScroll && !loading) {
        let scrollFraction;
        if (wWidth <= 800) {
          const maxScrollSide = outerDiv.scrollWidth - outerDiv.clientWidth;
          scrollFraction =
            (outerDiv.scrollLeft < 0 ? 0 : outerDiv.scrollLeft) / maxScrollSide;
        } else {
          let yScrollDiff = outerDiv.scrollTop - lastTimerYScrollValue;
          if (window.scrollY > 0) {
            outerDiv.scrollTop = lastTimerYScrollValue;
            window.scrollTo({
              top: window.scrollY + yScrollDiff,
              left: window.scrollX,
            });
            return;
          } else {
            lastTimerYScrollValue = outerDiv.scrollTop;
          }
          const maxScrollTop = outerDiv.scrollHeight - outerDiv.clientHeight;
          scrollFraction =
            (outerDiv.scrollTop < 0 ? 0 : outerDiv.scrollTop) / maxScrollTop;
        }
        frameIndex = Math.min(
          frameCount - 1,
          Math.ceil(scrollFraction * frameCount),
        );
        scrolled = frameIndex > 1 ? true : false;
        requestAnimationFrame(() => {
          countDown = Math.round(frameIndex / (frameCount / 60));
          countDown = countDown < 0 ? 0 : countDown;
          min = countDown;
          return updateImage(frameCount - frameIndex);
        });
      }
    });
  });
</script>

<section id="timer" class="h-screen w-screen">
  <header class={`${ignoreScroll && 'invisible'} h-3`}>
    <h1
      class="absolute w-full px-4 py-8 text-4xl text-center text-tomato shadow-lg top-1 leading-6 sm:leading-10"
    >
      juicy timer
      <br />
      <span
        class="w-full px-4 py-8 text-sm md:text-lg text-center text-tomato shadow-lg top-12"
      >
        Distraction free Pomodoro timer
      </span>
    </h1>
    {#if !scrolled}
      <p
        class="absolute w-full text-xl text-center text-tomato top-32 animate-pulse"
      >
        {wWidth <= 800 ? 'swipe to set time' : 'scroll to set time'}
      </p>
    {/if}
  </header>
  <div
    class={`h-full w-full ${
      wWidth <= 800
        ? 'overflow-x-scroll overflow-y-hidden'
        : 'overflow-y-clip overflow-x-hidden'
    }`}
    id="outer"
  >
    <div
      class={`${wWidth <= 800 ? 'h-full w-5x' : 'w-full h-5x'}`}
      id="inner"
    />
  </div>
  <p
    class={`absolute w-full top-1/2 text-center text-tomato animate-pulse ${
      !loading ? 'invisible' : ''
    }`}
  >
    Loading...
  </p>
  <canvas
    class={`${
      loading ? 'invisible' : ''
    } absolute top-1/2 left-1/2 transform -translate-y-2/4 -translate-x-2/4 max-h-screen max-w-screen`}
    id="timer-canvas"
  />
  <footer class="z-50">
    {#if countDown}
      <div class="z-20 w-full absolute text-center bottom-1">
        <button
          on:click={() => handleClick()}
          disabled={ignoreScroll}
          class={`${
            ignoreScroll && 'invisible'
          } z-10 h-10 text-lg leading-8 text-center text-pinkish hover:text-bgblack align-middle bg-tomato border-0 rounded-full shadow-lg w-36 bottom-16`}
          >Start</button
        >
        <CountDown {min} {sec} color="tomato" />
      </div>
    {/if}
  </footer>
</section>
<Modal {isOpen} {openClose} />
