<script lang="ts">
  export let min;
  export let sec;
  export let color;
</script>

<div class="bottom-24 w-full px-4 py-8">
  <h3 class={`text-4xl text-center text-${color} uppercase shadow-lg`}>
    {min}:{sec < 10 ? '0' + sec : sec}
  </h3>
</div>
