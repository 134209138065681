<script lang="ts">
  import { onMount } from 'svelte';
  import CountDown from './CountDown.svelte';
  import Footer from './Footer.svelte';

  export let isOpen: boolean;
  export let openClose: Function;
  let sec = 0;
  let min = 0;
  let startedAt = 0;
  let durationSeconds = 5 * 60;

  const update = () => {
    let now = Math.floor(Date.now() / 1000);
    let elapsedSeconds = now - startedAt;
    let secondsLeft = durationSeconds - elapsedSeconds;
    if (secondsLeft > 0) {
      sec = secondsLeft % 60;
      min = Math.floor(secondsLeft / 60);
      requestAnimationFrame(update);
    } else {
      openClose(false);
      min = 0;
      sec = 0;
      startedAt = 0;
    }
  };

  const triggerUpdate = () => {
    if (isOpen) {
      startedAt = Math.floor(Date.now() / 1000);
      update();
    } else {
      min = 0;
      sec = 0;
      startedAt = 0;
    }
  };

  function handleWindowKeyDown(event) {
    if (event.key === 'Escape') {
      openClose(false);
    }
  }

  $: isOpen, triggerUpdate();

  onMount(() => {
    window.addEventListener('keydown', handleWindowKeyDown);
  });
</script>

<div
  class={`${
    !isOpen && 'invisible'
  } block w-full h-full fixed z-20 bottom-0 overflow-y-hidden bg-black text-tomato`}
  aria-labelledby="success-modal"
  role="dialog"
  aria-modal="true"
>
  <button
    class="absolute top-1 right-1 border-0 mt-1 mr-1"
    on:click={() => openClose(false)}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6 hover:text-wesYellow text-pinkish"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-line-cap="round"
        stroke-line-join="round"
        stroke-width={2}
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>
  <div class="w-full h-full grid content-start md:content-center">
    <div class="text-center mt-12 pl-2 pr-2 md:m-0">
      <h2 class="text-4xl m-3">You made it! 🎉</h2>
      <h2 class="text-3xl m-3">Well done 💪</h2>
      <h2 class="text-2xl m-3">
        Now take a little break before your next success
      </h2>
      <CountDown {min} {sec} color="pinkish" />
    </div>

    <div class="absolute bottom-0 text-center w-full">
      <Footer footerMiddleSection={false} />
    </div>
  </div>
</div>
