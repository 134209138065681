<script lang="ts">
  let messages = [
    'to fuel more projects like this.',
    'to let me know you appreciate it.',
    'to let me know that it works for you.',
    'to help me pay for web hosting and domain.',
  ];

  export let footerMiddleSection: boolean = false;
</script>

<footer
  class="text-justify text-pinkish pt-6 sm:pt-6 bg-bgblack bottom-2 w-full sm:text-center p-4 sm:p-2 text-md md:text-lg"
>
  <p>
    juicy timer is and always will be <span class="text-red-500">FREE</span>
    and <span class="text-red-500">DISTRACTION FREE.</span> You can
    <a
      href="https://www.paypal.com/donate/?hosted_button_id=4T8ZY8JN5AZXE"
      target="_blank"
      rel="noopener noreferrer"
      class="text-wesYellow hover:no-underline hover:text-pinkish"
    >
      buy me a coffee <i data-emoji="☕️" />
    </a>
    {messages[Math.floor(Math.random() * messages.length)]}
  </p>
  <br />
  <p
    class={`${
      !footerMiddleSection ? 'hidden' : ''
    } text-left sm:text-center text-xs pb-2`}
  >
    juicy timer is not related to the Pomodoro Technique™/Pomodoro™ trademark
    held by Cirillo Company and respects its trademarks. Pomodoro Technique® and
    Pomodoro® are registered trademarks of Francesco Cirillo.
  </p>
  <p class="text-right sm:text-center">
    Made with <i data-emoji="❤️" /> by
    <a
      href="https://twitter.com/trizo_za"
      target="_blank"
      rel="noopener noreferrer"
      class="text-wesYellow hover:no-underline hover:text-pinkish"
    >
      Trizo
    </a>
  </p>
  <p class="text-right sm:text-center">
    ©️ {new Date().getFullYear()}
  </p>
</footer>
